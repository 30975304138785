import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { ChevronDown } from 'react-feather';

function NewsCategories({
  setFilterData,
  categoriesLabel,
  generalNewsCategoryLabel,
  communityNewsCategoryLabel,
  pressReleaseCategoryLabel,
  allNewsLabel,
  noticesCategoryLabel,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);
  const toggleRotate = dropdownOpen ? 'rotate(-180deg)' : 'rotate(0deg)';

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      data-testid="news-categories"
    >
      <DropdownToggle>
        {categoriesLabel}{' '}
        <ChevronDown
          size="18"
          style={{
            transform: toggleRotate,
            transition: 'transform .25s',
          }}
        />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => setFilterData('')}>
          {allNewsLabel}
        </DropdownItem>
        <DropdownItem onClick={() => setFilterData('general news')}>
          {generalNewsCategoryLabel}
        </DropdownItem>
        <DropdownItem onClick={() => setFilterData('press release')}>
          {pressReleaseCategoryLabel}
        </DropdownItem>
        <DropdownItem onClick={() => setFilterData('community news')}>
          {communityNewsCategoryLabel}
        </DropdownItem>
        <DropdownItem onClick={() => setFilterData('notices')}>
          {noticesCategoryLabel}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default NewsCategories;

import React, { useState, useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { graphql } from 'gatsby';
import BaseLayout from '../layouts/BaseLayout';
import IntroBlock from '../components/IntroBlock/IntroBlock';
import NewsCard from '../components/NewsCard/NewsCard';
import NewsCategories from '../components/NewsCategories/NewsCategories';
import LanguageContext from '../context/Language';

const LatestPage = ({ data }) => {
  const context = useContext(LanguageContext);
  const news = context.lang === 'en' ? 'enNews' : 'nlNews';

  const [filterData, setFilterData] = useState('');
  const filteredData = () => {
    if (!filterData || filterData === '') {
      return data[news].nodes;
    }

    return data[news].nodes.filter((article) => {
      return article.type[0] === filterData;
    });
  };

  const localData = data[context.lang];

  return (
    <BaseLayout title={localData.title} description={localData.seoDescription}>
      <IntroBlock data={localData} />
      <section className="section-padding-xs bg-light-gray-100">
        <Container>
          <Row className="pb-5">
            <Col></Col>
            <Col className="text-right">
              <NewsCategories
                setFilterData={setFilterData}
                categoriesLabel={localData.categoriesLabel}
                generalNewsCategoryLabel={localData.generalNewsCategoryLabel}
                communityNewsCategoryLabel={
                  localData.communityNewsCategoryLabel
                }
                pressReleaseCategoryLabel={localData.pressReleaseCategoryLabel}
                allNewsLabel={localData.allNewsLabel}
                noticesCategoryLabel={localData.noticesCategoryLabel}
              />
            </Col>
          </Row>
          <Row>
            {filteredData() &&
              filteredData().map((article) => (
                <Col sm="9" md="6" lg="4" className="mb-5" key={article.id}>
                  <NewsCard
                    title={article.title}
                    shortDescription={article.shortDescription}
                    type={article.type}
                    date={article.date}
                    slug={article.slug}
                    image={article.thumbnail}
                    callToActionLabel={article.callToActionLabel}
                    link={article.link}
                  />
                </Col>
              ))}
          </Row>
        </Container>
      </section>
    </BaseLayout>
  );
};

export default LatestPage;

export const query = graphql`
  query LatestNewsQuery {
    en: contentfulLatestNews(
      contentful_id: { eq: "79x6XYS8m8XnuH6fQqBJCg" }
      node_locale: { eq: "en-GB" }
    ) {
      id
      headerImage {
        ...HeaderImageFragment
      }
      introduction {
        ...IntroBlockFragment
      }
      title
      seoDescription
      categoriesLabel
      generalNewsCategoryLabel
      communityNewsCategoryLabel
      pressReleaseCategoryLabel
      noticesCategoryLabel
      allNewsLabel
    }
    nl: contentfulLatestNews(
      contentful_id: { eq: "79x6XYS8m8XnuH6fQqBJCg" }
      node_locale: { eq: "nl-NL" }
    ) {
      id
      headerImage {
        ...HeaderImageFragment
      }
      introduction {
        ...IntroBlockFragment
      }
      title
      seoDescription
      categoriesLabel
      generalNewsCategoryLabel
      communityNewsCategoryLabel
      pressReleaseCategoryLabel
      noticesCategoryLabel
      allNewsLabel
    }
    enNews: allContentfulNewsArticle(
      filter: { node_locale: { eq: "en-GB" } }
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        ...NewsCardFragment
      }
    }
    nlNews: allContentfulNewsArticle(
      filter: { node_locale: { eq: "nl-NL" } }
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        ...NewsCardFragment
      }
    }
  }
`;
